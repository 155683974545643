











import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { MolTabNavigation, OrgHeaderInfo } from "@/v2/new-design-system";
import { VoidReasonRouteEnum } from "@/module/void-reason";
import { CustomPaymentMethodRouteEnum } from "@/module/custom-payment-method";
import { BackOfficeSettingsRouteEnum } from "../../route/back-office-settings-route.enum";
import { VIEW_CUSTOM_PAYMENT_METHOD } from "@/utils/enums/permissions";
import { useStore } from "@/store";
import { useRouter } from "@/router";
import { t } from "@/i18n";

const css = bemBuilder("back-office-settings-main");

export default defineComponent({
  name: "BackOfficeSettingsMain",
  components: {
    MolTabNavigation,
    OrgHeaderInfo,
  },
  beforeRouteEnter(to, _from, next) {
    next((vm: any) => {
      if (to.name === BackOfficeSettingsRouteEnum.MAIN) {
        vm.redirectToView();
      }
    });
  },
  beforeRouteUpdate(to, _from, next) {
    if (to.name === BackOfficeSettingsRouteEnum.MAIN) {
      this.redirectToView();
    }
    next();
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const tabsAvailable = [
      {
        routeName: VoidReasonRouteEnum.MAIN,
        title: t("module.back_office_settings.tab_void_reasons"),
        permission: VIEW_CUSTOM_PAYMENT_METHOD,
      },
      {
        routeName: CustomPaymentMethodRouteEnum.MAIN,
        title: t("module.back_office_settings.tab_custom_payment_methods"),
        permission: VIEW_CUSTOM_PAYMENT_METHOD,
      },
    ];

    const { getters } = useStore();
    const tabNavigation = computed(() => {
      const authPermissions = getters["auth/getPermissions"];
      return tabsAvailable.filter((tab) =>
        authPermissions.includes(tab.permission)
      );
    });

    const router = useRouter();

    function redirectToView() {
      router.replace({
        name: tabNavigation.value[0].routeName,
        params: {
          businessId: props.businessId,
        },
      });
    }

    return {
      t,
      css,
      tabNavigation,
      redirectToView,
    };
  },
});
